


























































































































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { Notify, Toast, Dialog } from "vant";
import weChat from "weixin-js-sdk";
import Clipboard from "clipboard";
import QRCode from "qrcode";
import moment from "moment";
import { dispatch, Order, Express, Product, SubOrder, Cart } from "@/store";
import { url } from "@/api";
import { OrderStatus } from "@/enum";

export default Vue.extend({
  data() {
    return {
      moment,
      OrderStatus,
      loading: false,
      order: { order: { payment_method: "" } } as Order,
      aliPayQRCode: "",
      aliPayQRVisible: false,
      interval: 0,
      express: {} as Express,
      expressVisible: false,
      groupUrl: "",
      groupVisible: false,
      contactVisible: false,
      balance: 0,
    };
  },
  computed: {
    ...mapState("app", {
      isInWeChat: "isInWeChat",
      isInQQ: "isInQQ",
      isMobile: "isMobile",
    }),
    ...mapState("profile", {
      wechatOpenid: "wechatOpenid",
    }),
    ...mapState("settings", {
      expressSupplier: "expressSupplier",
      addressSettings: "address",
    }),
    isVirtual(): boolean {
      return (
        this.order.sub_orders.filter(
          (i) => i.product_snapshot.product.is_virtual
        ).length === this.order.sub_orders.length
      );
    },
    isCoinPay(): boolean {
      return this.order.order.is_coin_pay;
    },
  },
  methods: {
    getOrder() {
      const { id } = this.$route.params;
      const { address } = this.$route.query;
      if (id) {
        return dispatch.ordersGet(id).then((res) => {
          if (res.order.status === "unpaid") {
            Notify("请在下单后 3 分钟内完成支付，超时订单将会自动关闭");
          }
          if (this.order.order.payment_method) {
            // 轮询时不改变付款方式
            res.order.payment_method = this.order.order.payment_method;
          }
          this.order = res;
          this.order.sub_orders.sort((a, b) => a.id - b.id);
          // 检查修改收货地址状态
          const subOrder = this.order.sub_orders.find(
            (subOrder) =>
              subOrder.status === "paid" && !subOrder.address_info.edit_num
          );
          if (address && subOrder) {
            dispatch.addressesGet(address + "").then((res) => {
              dispatch
                .ordersAddressPost({ orderNo: id, address: res })
                .then(() => {
                  this.$router.replace({
                    path: this.$route.path,
                    query: { ...this.$route.query, address: "" },
                  });
                  this.getOrder().then(() => {
                    const subOrder = this.order.sub_orders.find(
                      (subOrder) =>
                        subOrder.status === "paid" &&
                        !subOrder.address_info.edit_num
                    );
                    if (!subOrder) {
                      Notify("收货地址修改成功");
                    }
                  });
                });
            });
          }
        });
      } else {
        this.$router.replace(this.$paths.orders);
        return new Promise((resolve) => resolve(true));
      }
    },
    getMedia(product: Cart) {
      if (product.sku.medias && product.sku.medias[0]) {
        return product.sku.medias[0];
      } else {
        return product.product.main_medias[0];
      }
    },
    onPay() {
      if (this.loading) {
        return;
      }
      // 订单待支付时轮询订单支付状态
      if (!this.interval) {
        this.interval = setInterval(this.getOrder, 3000);
      }
      let trade_type = "";
      if (this.isCoinPay) {
        trade_type = "MiaoCoin";
      } else {
        if (this.order.order.payment_method === "alipay") {
          trade_type =
            !this.isMobile || this.isInWeChat || this.isInQQ
              ? "AliPayPreCreate"
              : "AliPayWap";
        } else {
          if (!this.isMobile) {
            Toast("请在微信中打开或使用支付宝支付");
            return;
          }
          trade_type = this.isInWeChat ? "JSAPI" : "MWEB";
        }
      }
      this.loading = true;
      dispatch
        .ordersPayment({
          open_id: this.wechatOpenid,
          order_no: this.order.order.order_no,
          trade_type,
        })
        .then((res) => {
          if (trade_type === "JSAPI") {
            weChat.chooseWXPay({
              timestamp: res.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: res.nonce_str, // 支付签名随机串，不长于 32 位
              package: res.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: res.sign_type, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: res.pay_sign, // 支付签名
              success: () => {
                // 支付成功后的回调函数
                Notify("支付成功");
              },
            });
          } else if (trade_type === "MWEB") {
            Dialog.confirm({
              title: "在线支付提示",
              message:
                "支付完成后请耐心等待页面自动返回，没有自动返回请刷新页面，请勿重复支付。",
            });
            window.location.href =
              res.mweb_url +
              "&redirect_url=" +
              encodeURIComponent(window.location.href);
          } else if (trade_type === "AliPayPreCreate") {
            QRCode.toDataURL(res.qr_code).then((url: string) => {
              this.aliPayQRCode = url;
              this.aliPayQRVisible = true;
            });
          } else if (trade_type === "AliPayWap") {
            Dialog.confirm({
              title: "在线支付提示",
              message:
                "支付完成后请耐心等待页面自动返回，没有自动返回请刷新页面，请勿重复支付。",
            });
            window.location.href = res.content;
          } else if (trade_type === "MiaoCoin") {
            Toast({
              message: "支付中，请稍候...",
              duration: 2000,
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectAddress() {
      let subOrder = this.order.sub_orders.find(
        (subOrder) => subOrder.status === "paid"
      );
      if (subOrder) {
        let subOrder = this.order.sub_orders.find(
          (subOrder) =>
            subOrder.status === "paid" && !subOrder.address_info.edit_num
        );
        if (subOrder) {
          Notify("收货地址只可修改一次，请仔细检查确认");
          this.$router.push({
            path: this.$paths.addresses,
            query: {
              redirect: this.$route.fullPath,
              address: String(subOrder.address_info.id),
            },
          });
        } else {
          Notify("您已修改过收货地址，如有疑问请联系客服");
        }
      }
    },
    onProductClick(product: Product) {
      this.$router.push(this.$paths.products + "/" + product.product.id);
    },
    expressSupplierName(code: string) {
      const expressSupplier = this.expressSupplier.find(
        (item: Express) => item.code === code
      );
      return expressSupplier ? expressSupplier.name : code;
    },
    copyExpressNo() {
      const clipboard = new Clipboard(".express-clipboard");
      clipboard.on("success", () => {
        Notify("快递单号已复制");
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        Notify("快递单号复制失败");
        clipboard.destroy();
      });
    },
    showExpress(express: Express) {
      this.express = express;
      this.expressVisible = true;
    },
    onCustomClick() {
      window.open(
        url.AM65LessCustom + "?order_no=" + this.order.order.order_no,
        "_blank"
      );
    },
    onConfirm(subOrder: SubOrder) {
      Dialog.confirm({
        title: "确认收货",
        message: "为保障售后权益，请检查后再确认收货",
      }).then(() => {
        dispatch
          .ordersSubStatusPatch({
            ...subOrder,
            status: "confirmed",
          })
          .then(() => {
            Notify("已确认收货");
            this.getOrder();
          })
          .catch(() => {
            window.location.reload();
          });
      });
    },
    joinGroup(url: string) {
      this.groupUrl = url;
      this.groupVisible = true;
    },
  },
  created() {
    this.getOrder();
    dispatch.profileGetBalance().then((res) => {
      this.balance = res.sum;
    });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
});
